<template>
	<div class="content">
		<div class="topbox">
			<div style="height: 100%;" v-for="(item,index) in toplist" :key="index">
				<div class="onetop nochoosetext" @click="chooseTop(index)">
					<span :class="{'activespan': index === topIndex}">{{item}}</span>
					<div v-if="index === topIndex"></div>
				</div>
			</div>
		</div>
		<div class="rightbox">
			<company-info v-if="topIndex === 0"></company-info>
			<buy-product v-if="topIndex === 1"></buy-product>
		</div>
		
	</div>
</template>

<script>
	import companyInfo from "./companyInfo.vue"
	import buyProduct from "./buyProduct.vue"
	export default {
		data() {
			return {
				topIndex: 0,
				toplist: ['公司介绍','已购产品']
			}
		},
		components: {
			companyInfo,
			buyProduct
		},
		created() {
			// this.$store.commit('setTopIndex',10)
			this.setTilte()
		},
		methods: {
			setTilte() {
				document.title = this.toplist[this.topIndex]
			},
			chooseTop(index) {
				this.topIndex = index
				this.setTilte()
			}
		},
	}
</script>

<style scoped lang="scss">
	.onetop {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 3.75em 0 0;
		cursor: pointer;
		span {
			font-size: 1.125em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #444444;
			&.activespan {
				font-size: 1.125em;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2D74F4;
			}
		}
		div {
			width: 1.875em;
			height: 0.1875em;
			position: absolute;
			bottom: 0;
			background-color: #3286FF;
		}
	}
	.topbox {
		padding: 0 1.0625em;
		display: flex;
		align-items: center;
		width: calc(100% - 2.5em);
		height: 3.25em;
		background-color: #ffffff;
		margin: 0.9375em auto 0;
	}
	// .rightbox {
	// 	width: calc(100% - 15em);
	// 	min-height: calc(100vh - 5em);
	// 	max-height: calc(100vh - 5em);
	// 	background-color: #ffffff;
	// }
	.oneselect {
		cursor: pointer;
		width: 100%;
		height: 4.125em;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		div {
			width: 0.5em;
			height: 0.5em;
			background: #444444;
			border-radius: 50%;
			// margin: 0 1.25em 0 0;
			left: 4em;
			position: absolute;
		}
	}
	.leftbox {
		width: 15em;
		background-color: #ffffff;
		min-height: calc(100vh - 5em);
		max-height: calc(100vh - 5em);
		border-right: 1px solid #E7EAEF;
	}
	.content {
		width: 100%;
		// margin: 0 0;
		// min-height: calc(100vh - 5em);
		// border-top: 1px solid #E7EAEF;
		// display: flex;
	}
@media screen and (max-width: 1920px) {
	.content {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.content {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.content {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.content {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.content {
		font-size: 12px;
	}
}
</style>
