<template>
	<div class="contentinfo">
		<div class="titletext">
			公司介绍
		</div>
		<div class="infobox">
			<img src="@/assets/home/image13.png" class="companyavatar">
			<div class="infotext">
				<div class="name">
					合肥皮诺客网络科技有限公司
				</div>
				<div class="box1">
					<div class="oneinfo" style="width: 35%;">
						<span>企业法人：</span>
						<p>顾有良</p>
					</div>
					<div class="oneinfo" style="width: 60%;">
						<span>年营业额：</span>
						<p>5000万～10000亿</p>
					</div>
					<div class="oneinfo" style="width: 35%;">
						<span>公司官网：</span>
						<p>http://www.pinnoocle.net/</p>
					</div>
					<div class="oneinfo" style="width: 60%;">
						<span>公司地址：</span>
						<p>安徽省合肥市蜀山区南二环安粮城市广场国贸中心#2902</p>
					</div>
					<div class="oneinfo" style="width: 35%;">
						<span>联系电话：</span>
						<p>0551-12341234</p>
					</div>
					<div class="oneinfo" style="width: 60%;">
						<span>主营业务：</span>
						<p>软件开发、后台系统、小程序开发、微信H5页面、品宣材料、海报、易拉宝、logo、企业vis系统视觉等各项互联网外包项目业务</p>
					</div>	
				</div>
			</div>
		</div>
		<div class="companyintroduction">
			<div class="box2">
				<div></div>
				<span>公司介绍</span>
			</div>
			<div class="box3">
				合肥皮诺客网络科技有限公司（简称“皮诺客科技”）是一家专注于移动互联网平台研发和销售的互联网公司 ，于2014年3月27日正式注册成立，总部位于安徽合肥。
				合肥皮诺客网络科技有限公司的主营业务有微信建站、定制开发、托管运营、品牌策划和微销365源码系统。皮诺客科技的核心微信产品包括微相册、微网站、微商城、360度全景展示、微预约、微投票、会员卡、优惠券、大转盘、砸金
				蛋、微红包、分销系统等几十种。且经过多次行业研究和封闭式开发，皮诺客科技可根据不同行业的特殊需求度身定制出移动营销解决方案。　截至2015年5月10日，合肥皮诺客网络科技有限公司已服务300多家终端企业单位，合作客户
				有安徽福建商会，依立腾男装、肥东老母鸡、合肥阿瓦山寨、阿波罗KTV、蚌埠大明文化产业园、上海大众武汉汇鼎等等。
				企业自成立以来获得了多项荣誉。2014年，合肥皮诺客网络科技有限公司曾先后获得了安徽移动互联网商业联盟技术服务商、中国网络思维及应用协会常务理事等荣誉；2015年8月29日，合肥皮诺客网络科技有限公司成为安徽网络思维及应用协会副会长单位。
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import { companyDetail } from "@/network/api.js"
	export default {
		created() {
			
		},
		computed: {
			...mapState(['userInfo'])
		},
		methods: {
			// 获取企业信息
			async _companyDetail() {
				const res = await companyDetail({
					// enterpriseId: this.userInfo.
				})
			}
		},
	}
</script>

<style scoped lang="scss">
	.box3 {
		padding: 1.25em;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #666666;
		line-height: 1.75em;
		letter-spacing: 0.1em;
	}
	.box2 {
		width: 100%;
		height: 4.375em;
		display: flex;
		align-items: center;
		padding: 0 1.25em;
		border-bottom: 1px solid #E7EAEF;
		div {
			width: 0.375em;
			height: 0.375em;
			background: #212324;
			border-radius: 50%;
			margin: 0 0.875em 0 0;
		}
		span {
			font-size: 1em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #222222;
		}
	}
	.companyintroduction {
		width: calc(100% - 5.25em);
		margin: 1.25em 0 0 5.25em;
		border-radius: 2px;
		border: 1px solid #E7EAEF;
	}
	.oneinfo {
		display: flex;
		// align-items: center;
		margin: 0 0 1.25em 0;
		span {
			font-size: 14px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #666666;
			white-space: nowrap;
			line-height: 1.45em;
		}
		p {
			color: #222222;
			line-height: 1.45em;
		}
	}
	.box1 {
		width: 100%;
		padding: 1.25em 1.25em 0;
		margin: 1em 0 0;
		background-color: #F6F9FD;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.infotext {
		margin: 0 0 0  1.25em;
		width: calc(100% - 5.25em);
	}
	.name {
		font-size: 1.25em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #0D0D0D;
	}
	.infobox {
		width: 100%;
		margin: 1.875em 0 0;
		display: flex;
	}
	.companyavatar {
		width: 4em;
		height: 4em;
		border-radius: 50%;
	}
	.titletext {
		font-size: 1.25em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #0F0F0F;
	}
	.contentinfo {
		background-color: #ffffff;
		width: calc(100% - 2.5em);
		margin: 0.9375em auto 0;
		padding: 1.5em;
	}
@media screen and (max-width: 1920px) {
	.contentinfo {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.contentinfo {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.contentinfo {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.contentinfo {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.contentinfo {
		font-size: 12px;
	}
}
</style>
