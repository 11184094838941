<template>
	<div class="contentinfo">
		<div class="titletext">
			已购产品
		</div>
		<div class="allproducts">
			<div class="oneproduct" v-for="(item,index) in 5" :key="index">
				<div class="box1">
					<div class="box2">
						<img src="../../assets/login/image5.png" class="productavatar">
						<div class="">
							<p>客户管理</p>
							<span>到期时间：<span style="color: #FA6400;">2022-04-14 12:00</span></span>
						</div>
					</div>
					<div class="box3">
						<div>即将到期</div>
						<span>还有 <span style="color: #FA6400;">2</span>天 到期！会员续费享8折优惠～</span>
					</div>
				</div>
				<div class="box4">
					<div class="productallinfo">
						<div class="productoneinfo">
							<p>系统编号：</p>
							<span>234234657687688</span>
						</div>
						<div class="productoneinfo">
							<p>登陆地址：</p>
							<span>http://www.pinn</span>
						</div>
						<div class="productoneinfo">
							<p>备份上线：</p>
							<span>5份（当前已使用1份）</span>
						</div>
						<div class="productoneinfo">
							<p>最近备份：</p>
							<span>2022-01-14 15:0</span>
						</div>
						<div class="productoneinfo">
							<p>购买时间：</p>
							<span>2021-02-14 12:0</span>
						</div>
						<div class="productoneinfo">
							<p>续费价格：</p>
							<span>¥8934 /年</span>
						</div>
						
					</div>
					<div class="allbtn">
						<div class="resetpasswordbtn">
							重置密码
						</div>
						<div class="resetpasswordbtn">
							系统备份
						</div>
						<div class="xufeibtn">
							我要续费
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped lang="scss">
	.allbtn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10% 1.25em 1.25em 0;
		div {
			margin: 0 0 0 1.875em;
			cursor: pointer;
		}
		div:active {
			opacity: 0.6;
		}
	}
	.xufeibtn {
		padding: 1.3% 4.6%;
		border-radius: 1.375em;
		display: flex;
		background-color: #3266F7;
		align-items: center;
		justify-content: center;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #ffffff;
	}
	.resetpasswordbtn {
		padding: 1.3% 4.6%;
		border-radius: 1.375em;
		border: 1px solid #AAAAAA;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
	.productallinfo {
		display: flex;
		flex-wrap: wrap;
		padding: 3.7% 0 0 12%;
	}
	.productoneinfo {
		width: 45%;
		display: flex;
		align-items: flex-start;
		margin: 0 0 3.2% 0;
		p {
			white-space: nowrap;
			font-size: 1em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
		}
		span {
			font-size: 1em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #222222;
		}
	}
	.box3 {
		display: flex;
		flex-direction: column;
		// justify-content: flex-end;
		align-items: flex-end;
		div {
			margin: 0 0 1.25em;
			width: 6.25em;
			height: 2.375em;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(254,239,229);
			border-radius: 1.15em 0 1.15em 1.15em;
			font-size: 1em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #FA6400;
		}
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
		}
	}
	.box2 {
		display: flex;
		align-items: center;
		img {
			width: 4em;
			height: 4em;
			border-radius: 0.625em;
			margin: 0 0.9375em 0 0;
		}
		div {
			p {
				font-size: 1.125em;
				margin: 0 0 0.5em;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #111111;
			}
			span {
				font-size: 0.875em;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}
	}
	.box1 {
		padding: 1.375em 1.375em 0.625em;
		display: flex;
		border-bottom: 1px solid #EEEEEE;
		align-items: flex-start;
		justify-content: space-between;
	}
	.oneproduct {
		width: calc(50% - 0.625em);
		// height: 21vw;
		// min-height: 250px;
		margin: 0 0 1.25em;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(50, 102, 247, 0.14);
		border-radius: 2px;
	}
	.allproducts {
		margin: 2.3125em 0 0;
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.titletext {
		font-size: 1.25em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #0F0F0F;
	}
	.contentinfo {
		width: calc(100% - 2.5em);
		margin: 0.9375em auto 0;
		padding: 1.5em;
		background-color: #ffffff;
	}
@media screen and (max-width: 1920px) {
	.contentinfo {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.contentinfo {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.contentinfo {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.contentinfo {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.contentinfo {
		font-size: 12px;
	}
}
</style>
